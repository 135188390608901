.modal-sm .modal-content {
  border-radius: 20px;
}

.modal-title.h4 {
  font-family: 'Gilroy-SemiBold', sans-serif;
  color: var(--boulder);
  font-size: 14px;
}

.modal-sm .modal-header {
  padding-top: 35px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--silver);
}

.modal-sm .modal-body {
  padding: 30px 40px 40px 40px;
}

.modal-body h4 {
  font-family: 'Gilroy-SemiBold', sans-serif;
  color: var(--boulder);
  font-size: 18px;
  margin-bottom: 18px;
}

.modal-body p {
  font-family: 'Gilroy-Medium', sans-serif;
  color: var(--gray);
  font-size: 14px;
  line-height: 1.8;
}

.modal-sm .modal-footer {
  padding: 0px 40px 40px 40px;
  border-top: none;
  margin: 0px;
}

@media (min-width: 576px) {
  .modal-sm {
    --bs-modal-width: 466px;
  }
}
