/***************************/
/******** Buttons *********/

.btn {
  border-radius: 6px;
  transition: 300ms;
  margin: 0px;
}

.spinner-border {
  --bs-spinner-animation-speed: 1.5s;
}

/* PRIMARY */
.btn-primary {
  font-family: 'Gilroy-SemiBold', sans-serif;
  background-color: var(--forest);
  border-color: var(--forest);
  color: var(--white);
  --bs-btn-focus-shadow-rgb: transparent;
}

.btn-primary:hover {
  background-color: var(--ocean);
  border-color: var(--ocean);
}

.btn-primary:active,
.btn-primary:focus {
  background-color: var(--tiber);
  color: var(--hitGray);
  border-color: var(--tiber);
}

/* Secondary */
.btn-secondary {
  font-family: 'Gilroy-Bold', sans-serif;
  background-color: var(--white);
  color: var(--success);
  border-color: var(--seashell);
  --bs-btn-focus-shadow-rgb: transparent;
}

.btn-secondary:hover {
  background-color: var(--success);
  color: var(--white);
  border-color: var(--success);
}

.btn-secondary:active,
.btn-secondary:focus {
  background-color: var(--darkGrass);
  color: var(--white);
  border-color: var(--darkGrass);
  box-shadow: none;
}

/*spinner color for secondary btn */
.btn-secondary div svg path {
  stroke: var(--success);
}
.btn-secondary:hover div svg path {
  stroke: var(--white);
}

/* Inverted */
.btn-inverted {
  background-color: var(--white);
  color: var(--forest);
  font-family: 'Gilroy-SemiBold', sans-serif;
  font-size: 18px;
}

.btn-inverted:hover {
  background-color: var(--forest);
  border-color: var(--forest);
  color: var(--white);
}

.btn-inverted:active,
.btn-inverted:focus {
  background-color: var(--jungle);
  border-color: var(--jungle);
  color: var(--white);
  box-shadow: none;
}

/*spinner color for inverted btn */
.btn-inverted div svg path {
  stroke: var(--forest);
}

.btn-inverted:hover div svg path {
  stroke: var(--white);
}

@media only screen and (max-width: 768px) {
  .btn-inverted:active {
    background-color: var(--gullGray);
    border-color: var(--gullGray);
    color: var(--forest);
  }
}

/* Misc / All */
.btn-primary:disabled,
.btn-secondary:disabled,
.btn-inverted:disabled {
  background-color: var(--mercury);
  color: var(--white);
  border-color: var(--mercury);
}

/***** Sizes ******/
.btn-sm {
  min-width: 140px;
  height: 56px;
  font-size: 1.125rem;
  text-align: center;
}

.btn-primary .btn-med {
  font-size: 16px;
}

.btn-med {
  height: 48px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 300px;
}

/* spinner */
.btn-med div svg {
  width: 7rem !important;
}

.btn-sm div svg {
  width: 7rem !important;
}

.btn-inverted.btn-lg svg {
  width: 7rem !important;
}

.btn-primary.btn-lg svg {
  width: 8rem !important;
}

.btn-lg {
  height: 56px;
  min-width: 245px;
  text-align: center;
}

.btn-inverted.btn-lg {
  width: 300px;
}

/******** / Buttons *********/
/****************************/
