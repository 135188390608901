/* hides horizontal scrollbar */
.carousel-row {
  overflow-y: hidden;
  overflow-x: auto;
  margin-left: 2px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border-radius: 6px;
  -webkit-overflow-scrolling: touch;
}
.carousel-row::-webkit-scrollbar {
  display: none;
}

@media (min-width: 768px) and (max-width: 1400px) {
  .carousel-row {
    margin-right: 0px;
  }
}
