/* Bootstrap Overrides */

.offcanvas-header .btn-close {
  background: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='%230C5153' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.7929 14.2071C13.1834 14.5976 13.8166 14.5976 14.2071 14.2071C14.5976 13.8166 14.5976 13.1834 14.2071 12.7929L8.66421 7.25L14.2071 1.70711C14.5976 1.31658 14.5976 0.683417 14.2071 0.292893C13.8166 -0.0976311 13.1834 -0.0976311 12.7929 0.292893L7.25 5.83579L1.70711 0.292893C1.31658 -0.0976317 0.683417 -0.0976317 0.292893 0.292893C-0.0976315 0.683417 -0.0976315 1.31658 0.292893 1.70711L5.83579 7.25L0.292893 12.7929C-0.0976311 13.1834 -0.0976311 13.8166 0.292893 14.2071C0.683417 14.5976 1.31658 14.5976 1.70711 14.2071L7.25 8.66421L12.7929 14.2071Z' /%3E%3C/svg%3E")
    center/1em auto no-repeat;
  opacity: 1;
}

.nav-link,
.nav-link:visited,
.nav-link:hover,
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: var(--forest);
}

@media screen and (min-width: 992px) {
  .nav-link {
    border-radius: 6px;
    padding: 10px 8px;
  }

  .nav-link:hover {
    background-color: rgba(12, 81, 83, 0.1);
    padding: 10px 8px;
  }
}
