:root {
  /* Custom for web */

  --seashell: #f1f1f1;
  --desert: #e5e5e5;
  --mercury: #e4e4e4;
  --hitGray: #9cb1b2;
  --gullGray: #99b2b5;

  --tiber: #083c3e;
  --darkForestGreen: #135658;
  --scandal: #bcf9e4;

  --scandal_rgb: 188, 248, 228;

  /* opacity */
  --alpha5: 0.5;

  /* Below matches @constants/colors.ts */
  --white: #ffffff;
  --primaryBackground: #f9f9f7;

  --warning: #fec636;
  --zest: #c6ec76;
  --lime: #8ed66e;
  --laurel: #72937f;
  --greenPea: #3e7b56;
  --amazon: #386b4c;
  --goblin: #366649;

  --primaryText: #082223;

  --robinEgg: #069ec2;
  --walk: #329da3;
  --forest: #0c5153;
  --july: #069ec2;
  --success: #00ae70;
  --darkGrass: #009262;
  --ocean: #007782;
  --jungle: #003f45;

  --black: #000000;

  --offWhite: #f6f6f6;
  --snowWhite: #fafafa;
  --sky: #ebf9fb;
  --whisper: #ebebeb;
  --lighterGreen: #e8fdf6;

  --lightGray: #f1f1f1;

  --persimmon: #ff5f4e;

  --lightestGray: #ccc;
  --lighterGray: #a6a6a6;
  --gray: #777777;

  --stromboli: #356447;
  --mediumGray: #3e3e3c;
  --darkGray: #3c3c3c;
  --charcoal: #1d1d1d;
  --forest: #0c5153;
  --darkGreen: #003f45;
}

::selection {
  color: var(--success);
  background-color: rgba(var(--scandal_rgb), var(--alpha5));
}

:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

.main-background {
  color: var(--desert);
}

a,
a:visited {
  color: var(--success);
  font-family: 'Gilroy-SemiBold', sans-serif;
  transition: color 300ms ease;
}

a:hover {
  color: var(--forest);
}

h1,
.h1,
.title {
  font-family: 'Gilroy-Bold', sans-serif;
  font-size: 40px;
  color: var(--mediumGray);
  margin-bottom: 20px;
  line-height: normal;
}

p,
.p,
.description {
  font-family: 'Gilroy-SemiBold';
  color: var(--gray);
  font-size: 16px;
}

/* Font families */
.font-light {
  font-family: 'Gilroy-Light', sans-serif;
}

.font-regular {
  font-family: 'Gilroy-Regular', sans-serif;
}

.font-medium {
  font-family: 'Gilroy-Medium', sans-serif;
}

.font-semiBold {
  font-family: 'Gilroy-SemiBold', sans-serif;
}

.font-bold {
  font-family: 'Gilroy-Bold', sans-serif;
}

.font-extraBold {
  font-family: 'Gilroy-ExtraBold', sans-serif;
}

/* Font colors */
.darkGreen {
  color: var(--darkForestGreen);
}

.primary-forest {
  color: var(--forest);
}

.secondary {
  color: var(--jungle);
}

/* Spacing */

/* Padding */
.pt-40 {
  padding-top: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}

.pt-30 {
  padding-top: 30px;
}
.pb-30 {
  padding-bottom: 30px;
}

/* Margin */

.mb-20 {
  margin-bottom: 20px;
}
.mr-20 {
  margin-right: 20px;
}

.mt-30 {
  margin-top: 30px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mr-30 {
  margin-right: 30px;
}

.mt-50 {
  margin-top: 50px;
}
.mb-50 {
  margin-bottom: 50px;
}

/* Bootstrap Overrides */

.border-bottom {
  border-bottom: 1px solid var(--whisper) !important;
}
