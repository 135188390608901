.qr-modal .btn-close {
  position: absolute;
  top: 25px;
  right: 25px;
  background: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='%23777' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.7929 14.2071C13.1834 14.5976 13.8166 14.5976 14.2071 14.2071C14.5976 13.8166 14.5976 13.1834 14.2071 12.7929L8.66421 7.25L14.2071 1.70711C14.5976 1.31658 14.5976 0.683417 14.2071 0.292893C13.8166 -0.0976311 13.1834 -0.0976311 12.7929 0.292893L7.25 5.83579L1.70711 0.292893C1.31658 -0.0976317 0.683417 -0.0976317 0.292893 0.292893C-0.0976315 0.683417 -0.0976315 1.31658 0.292893 1.70711L5.83579 7.25L0.292893 12.7929C-0.0976311 13.1834 -0.0976311 13.8166 0.292893 14.2071C0.683417 14.5976 1.31658 14.5976 1.70711 14.2071L7.25 8.66421L12.7929 14.2071Z' /%3E%3C/svg%3E")
    center/1em auto no-repeat;
}

.app-link:hover {
  background-color: #000;
}

.app-link:hover svg .appleBackground {
  fill: #000;
}

.app-link:hover svg .appleText,
.app-link:hover svg .appleIcon,
.app-link:hover svg .googleTextBottom,
.app-link:hover svg .googleTextTop,
.app-link:hover svg .googleIcon {
  fill: #fff;
}
