@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: local('Gilroy-ExtraBold'), url('./fonts/Gilroy-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url('./fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  src: local('Gilroy-SemiBold'), url('./fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url('./fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: local('Gilroy-Regular'), url('./fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url('./fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 200;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
